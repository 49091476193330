import LoginForm from './Components/LoginForm/LoginForm';


function App() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}

export default App;
